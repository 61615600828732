import { Layout, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import ReleaseCardMarkdown from "./components/release-card-markdown/ReleaseCardMarkdown";
import releaseFiles from "./data";
import { HeaderTitle, PaginationWrapper, Wrapper } from "./styles";

releaseFiles.reverse()

function App() {
  const [items] = useState<string[]>(releaseFiles)
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [currentItems, setCurrentItems] = useState<string[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const slice = items.slice(itemOffset, endOffset);
    console.log(slice);
    setCurrentItems(slice);
  }, [items, itemOffset, itemsPerPage]);

  const handlePageClick = (pageNumber: number, _pageSize: number) => {
    const newOffset = ((pageNumber - 1) * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${pageNumber}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    setCurrentPage(pageNumber)
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setItemsPerPage(size);
    handlePageClick(current, size);
    setCurrentPage(current)
  };

  return (
    <Layout>
      <Layout.Header>
        <HeaderTitle>BILDIT CMS Releases</HeaderTitle>
      </Layout.Header>
      <Layout>
        <Layout.Content>
          <Wrapper>
            <PaginationWrapper>
              <Pagination
                showSizeChanger
                current={currentPage}
                onShowSizeChange={handlePageSizeChange}
                onChange={handlePageClick}
                hideOnSinglePage={false}
                pageSizeOptions={[2, 4, 10, 20, 100]}
                pageSize={itemsPerPage}
                total={items.length}
              />
            </PaginationWrapper>
            {currentItems.map((item) => (
              <ReleaseCardMarkdown key={item} filename={item} />
            ))}

            <PaginationWrapper>
              <Pagination
                current={currentPage}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
                onChange={handlePageClick}
                hideOnSinglePage={false}
                pageSizeOptions={[2, 4, 10, 20, 100]}
                pageSize={itemsPerPage}
                total={items.length}
              />
            </PaginationWrapper>
          </Wrapper>
        </Layout.Content>
      </Layout>
      <Layout.Footer>BILDIT @2022</Layout.Footer>
    </Layout>
  );
}

export default App;
