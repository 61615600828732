
import styled from 'styled-components';

export const HeaderTitle = styled.h1`
  color: white;
  text-align: center;
`;

export const Wrapper = styled.div`
  padding: 40px;
`

export const PaginationWrapper = styled.div`
  text-align: right;
  margin-bottom: 8px;
`