import Markdown from 'react-markdown'
import React, { useEffect, useState } from 'react'
import { Container } from './styles'

type Props = {
    filename: string
}
const ReleaseCardMarkdown = ({filename}: Props) => {
    const [content, setContent] = useState("");

    useEffect(() => {
      import(`../../release-notes/${filename}`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => setContent(res))
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }, [filename]);

    return (
        <Container>
            <Markdown>{content}</Markdown>
        </Container>
    )
}

export default ReleaseCardMarkdown